import _ from 'lodash';

import {
  GetVisitTypeMemberships,
  VisitType,
  VisitTypeVerbiage,
} from 'ev-types';

import api, { Base, Tags } from 'ev-api/api';
import {
  sanitizeAndTransformResponse,
  sanitizeAndTransformResponseData,
} from 'ev-api/common/transformers';

import {
  GetVisitType,
  GetVisitTypesById,
  GetVisitTypesMembershipParams,
  GetVisitTypesParams,
  GetVisitTypeVerbiagesParams,
} from './params';
import { visitTypesTransformer, visitTypeTransformer } from './transformers';

export const visitTypesApi = api.injectEndpoints({
  endpoints: builder => ({
    getVisitTypes: builder.query<VisitType[], GetVisitTypesParams>({
      query: ({ practiceId, userId, loadProviderGroups, scope }) => ({
        url: `${Base.V3}/visit_types`,
        params: {
          practice_id: practiceId,
          user_id: userId,
          limit: 20,
          offset: 0,
          provider_groups: loadProviderGroups,
          scope: scope,
        },
      }),
      transformResponse: visitTypesTransformer,
      providesTags: [Tags.VisitTypes],
    }),
    getVisitTypesById: builder.query<VisitType[], GetVisitTypesById>({
      query: ({ ids }) => ({
        url: `${Base.V3}/visit_types/bulk_show`,
        method: 'PUT',
        body: {
          ids: _.uniq(ids),
        },
      }),
      transformResponse: visitTypesTransformer,
    }),
    getVisitType: builder.query<VisitType, GetVisitType>({
      query: ({ id, showAll }) => ({
        url: `${Base.V3}/visit_types/${id}`,
        method: 'GET',
        params: {
          show_all: showAll,
        },
      }),
      transformResponse: visitTypeTransformer,
    }),
    getVisitTypeMemberships: builder.query<
      GetVisitTypeMemberships,
      GetVisitTypesMembershipParams
    >({
      query: ({
        visitTypeId,
        availability = 'all',
        search,
        advanced_search,
        limit = 20,
        offset = 0,
        sortByLastName = false,
        disablePaginate = false,
        state,
        defaultProviderId,
      }) => ({
        url: `${Base.V3}/visit_types/${visitTypeId}/visit_type_memberships`,
        params: {
          availability,
          search,
          advanced_search,
          limit,
          offset,
          disable_paginate: disablePaginate,
          sort_by_last_name: sortByLastName,
          state,
          default_provider_id: defaultProviderId,
        },
      }),
      transformResponse: sanitizeAndTransformResponseData,
      providesTags: [Tags.VisitTypeMemberships],
    }),
    getVisitTypeCustomVerbiages: builder.query<
      VisitTypeVerbiage[],
      GetVisitTypeVerbiagesParams
    >({
      query: ({ visitTypeIds }) => ({
        url: `${Base.V3}/visit_type_custom_verbiages/bulk_show`,
        method: 'PUT',
        body: {
          visit_type_ids: _.uniq(visitTypeIds),
        },
      }),
      transformResponse: sanitizeAndTransformResponse,
    }),
  }),
});

export const {
  useGetVisitTypesQuery,
  useGetVisitTypeMembershipsQuery,
  useLazyGetVisitTypeMembershipsQuery,
  useGetVisitTypesByIdQuery,
  useGetVisitTypeQuery,
  useGetVisitTypeCustomVerbiagesQuery,
} = visitTypesApi;
